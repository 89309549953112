import {
  addAnimationClassOncePartiallyVisible,
  execAfterDomContentLoaded,
} from "../../util";

let elMap: {
  wizardImgEl: HTMLElement;
};

execAfterDomContentLoaded(afterContentLoaded);

async function afterContentLoaded() {
  elMap = {
    wizardImgEl: document.getElementById("wizard-ape"),
  };

  addAnimationClassOncePartiallyVisible(
    elMap.wizardImgEl,
    "animate__bounceInUp"
  );
}
